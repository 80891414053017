.downloadsPage-accordion-item .accordion-button {
  background-color: rgb(235, 235, 235);
  transition: all 0.3s ease-in;
}

.downloadsPage-accordion-item .accordion-button:focus {
  box-shadow: none !important;
}

.category-name {
  text-transform: uppercase;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.pagination .page-link:focus {
  border: none !important;
  box-shadow: none !important;
}

.downloadsPage-downloads .dropdown-item a {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0.5rem 1rem;
}

.downloadsPage-downloads .dropdown-item a:hover {
  background-color: #0033a0;
  color: white;
}

.downloadsPage-downloads .dropdown-item {
  padding: 0 !important;
}

td {
  font-size: 0.85rem !important;
}

td > a {
  font-size: 0.85rem;
}

td > span {
  display: block;
}

td .download-link {
  border: 1px solid rgb(216, 216, 216);
  padding: 0.5rem 1rem;
  border-radius: 2px;
}

tr,
th {
  border-collapse: collapse !important;
  border: 1px solid rgb(238, 238, 238) !important;
}

td {
  border: 1px solid rgb(236, 236, 236) !important;
  border-collapse: collapse !important;
}

thead,
tbody {
  border: 1px solid rgb(238, 238, 238) !important;
  border-collapse: collapse !important;
}



.downloadIcon {
  margin-left: 0.2rem;
}


.nodata-warning {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 576px) {
  .downloadsPage-downloads .download-text{
    display: none;
  }
}



/* #endregion */
