.Department_departmentheading h3 {
  font-weight: bold;
  margin-top: 10px;
  color: #464545;
}

.Department-departmentheading {
  color: white;
  padding: 0.3rem 1rem !important;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.Department-departmentlinkitem {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 0rem;
  color: #464545;
}
/* Department Details css */

.department-sections {
  list-style: none;
  padding: 0;
}

.hodimage {
  float: left;
  width: 100px;
  margin-right: 10px;
  border: 4px solid #e2e0e0;
}

.department-section-tab-list + .tab-content {
  min-height:80px;
  overflow-y: scroll;
  background-color: #f6f6f6;
  padding: 0.9rem;
  text-align: justify;
}

.banner_image {
  position: relative;
}
.banner-description {
  /* background-color: antiquewhite; */
  position: absolute;
  /* width: 100%; */
  top: 35%;
  left: 12%;
  z-index: 2;
}

.banner-description h4 {
  color: white;
  margin: 0;
}

.banner-description h4 {
  font-weight: bold;
}

.banner-breadcrumb a {
  color: white;
  margin: 0;
}

.department-links .dep-link-item {
  text-align: center;
  border-right: solid 1px #bdbab9;
  transition: all 130ms ease-in-out 0s;
}
.department-links .dep-link-item a {
  display: block;
  padding: 2rem;
  color: white;
}

.department-links .dep-link-item a p {
  color: white;
  font-weight: bold;
}
.department-links .dep-link-item a p svg {
  font-size: 2.2rem;
}

.department-links .dep-link-item .dep-link-text {
  margin-top: 10px !important;
}


.department-links .row{
  --bs-gutter-x:0;
}

@media only screen and (max-width: 576px) {

  .department-links .dep-link-item{
    border-bottom: 1px solid #fff;
  }
}