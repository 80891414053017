.wiser-carousal .carousel-caption {
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background-color:rgb(26 26 26 / 40%);
}

.wiser-carousal .carousel-caption a{
  color: #fff;
}

.wiser-carousal .carousel-control-next-icon,
.wiser-carousal .carousel-control-prev-icon {
  background-color: #ff0000;
  border-radius: 100%;
}

.wiser-carousal img {
  /* height: 650px; */
  width: 100%;
  object-fit: cover;
}
.wiser-carousal-wrapper{
  position: relative;
  margin-top: 20px;
}

.carousal-buttons{
  position: absolute;
  right: 0;
  left: 0;
  width: 80%;
  margin: auto;
  z-index: 1;
  bottom: -38px;
}

@media only screen and (max-width: 576px) {
  .wiser-carousal-wrapper{
    position: static;
  }
  .carousal-buttons{
    position: static;
    width: 100%;
  }
  .wiser-carousal img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
  .wiser-carousal .carousel-caption h3 {
    font-size: 14px;
    font-weight: bold;
  }
  .wiser-carousal .carousel-caption p {
    font-size: 12px;
  }
  .wiser-carousal .carousel-caption {
    transform: none;
    bottom: 1.2rem !important;
    top: 20%;
    background-color: rgba(0, 0, 0, 0.45);
  }
}
