.iqacPage-heading {
  color: rgb(85, 85, 85);
}

.iqacPage-list {
  list-style-type: none;
}

.iqacPage-list-item {
  background-color: #f7f7f7;
  margin-top: 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iqacPage-download-btn {
  border: none;
  font-size: 0.8rem;
  padding: 0.7rem 1rem;
  color: rgb(107, 107, 107);
}

.iqacPage-date {
  color: red;
}

.iqacPage-link-item {
  text-align: justify;
  padding-right: 0.5rem;
}

.iqacPage-link-item:hover {
  text-decoration: underline;
}

.iqacPage-pagination {
  list-style-type: none;
  display: flex;
}

.IqacPage-pageNumber:focus {
  outline: none !important;
  box-shadow: none !important;
}

.iqacPage-iqacIcon {
  border: none;
  font-size: 0.8rem;
  padding: 0.7rem 1rem;
  color: rgb(107, 107, 107);
}

.IqacPage-link {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.iqacPage-downloadtext {
  margin-right: 0.3rem;
}

@media only screen and (max-width: 700px) {
  .IqacPage-pageNumber {
    font-size: 0.7rem !important;
  }

  .iqacPage-pagination {
    padding-left: 0;
  }

  .iqacPage-downloadtext {
    display: none;
  }

  .iqacPage-link-item {
    font-size: 0.7rem;
  }

  .iqacPage-heading {
    font-size: 1rem;
    margin-left: 0 !important;
  }
}

.iqacPage-tabs {
  background-color: #ececec;
}

.iqacPage-tabs .nav-link {
  border-radius: 0 !important;
}

.iqacPage-tabs .active {
  color: #f7f7f7 !important;
}


.iqac-wrapper{
  padding: 20px 0;
  min-height: 400px;
  background: antiquewhite;
  background-color: #d9d8d857;
  border: 1px solid #d9d8d8f2;
}
.iqac-content-wrapper{
  padding: 7px;
}
.iqac-content{
  background-color: #fff;
  border: 1px solid #cdcccc;
}

.iqac-content .list-group-iqac .list-group-item{
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  padding: .7rem;
  display: block;
}

.iqac-content .list-group-iqac .list-group-item:hover{
  background-color: #bebebe57;
  color: black;
}

.list-group-item.active-item{
  background-color: #003366 !important;
  color: white !important;
  transition: 0.3s;
}


.iqac-content .main-iqac-content {
  padding: 0.9rem 0.7rem;
}

.link-head p{
  font-size: .8rem;
}