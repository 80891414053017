.staff-table{
    margin-bottom: 34px;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 1px 1px #f3f3f3
}
.staff-table th,.staff-table td{
    border:1px solid rgb(205 203 203) !important;
    font-size: 13px;
}

.staff-table .heading-col{
    text-align: center;
}

.staff-table .heading-col strong{
    font-size: 14px;
}