.error-title {
  font-size: 6rem;
}

.error-heading {
  font-weight: 700;
  font-size: 2rem;
}

.error-text {
  font-size: 1rem;
}

.btn-text {
  padding: 0.5rem 1rem;
  border-radius: 2px;
}

.img-wrapper img {
  height: 100%;
  width: 100%;
}
