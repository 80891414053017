.CCollegeService-ServiceData {
  color: #464545;
}

.CCollegeService-ServiceData table,
td,
th {
  border: 1px solid black;
  margin-top: 2rem;
}

/* .CCollegeService-ServiceData table td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
} */

.CCollegeService-ServiceData table tr:first-child {
  padding-top: 12px;
  font-weight: bold;
  padding-bottom: 12px;
  text-align: left;
}

.CCollegeService-ServiceData table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.CCollegeService-ServiceData table td,
.CCollegeService-ServiceData table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.CCollegeService-ServiceData table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.CCollegeService-ServiceData table tr:hover {
  background-color: #ddd;
}
