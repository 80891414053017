.sp-navbar-green {
  padding: 0 !important;
}

.sp-navbar-green .navbar-nav .nav-link {
  color: #fff !important;
  padding: 11px 18px !important;
  font-weight: 550;
  font-size: 13px;
  text-transform: uppercase;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  background-color: black;
}

.navbar-toggler {
  border-radius: 0 !important;
}

.navbar-dark .navbar-toggler {
  color: transparent !important;
  border: none;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.wiser-dropdown .dropdown-menu {
  border-radius: 0 !important;
  margin-top: 0 !important;
}

.dropdown .dropdown-menu {
  -webkit-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
  -moz-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
  -ms-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
  -o-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
  transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.dropdown.show .dropdown-menu {
  -webkit-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
  -moz-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
  -ms-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
  -o-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
  transition: max-height 0.3s, opacity 0.2s, visibility 0s;
  display: inline-table;
  height: auto;
  opacity: 1;
  visibility: visible;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  border: 1px solid #e2dddd;
  padding: 0;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: black !important;
}

.dropdown-item {
  padding: 8px 18px !important;
  font-weight: 550 !important;
  font-size: 14px;
  color: #666666 !important;
}

.dropdown-item:hover {
  background-color: black !important;
  color: #fff !important;
}

@media only screen and (max-width: 576px) {
  .sp-navbar-green {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px !important;
    box-shadow: 0px 0px 4px 2px #c1c1c1;
  }
 
  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .navbar-dark .navbar-toggler {
    border: none;
  }
}