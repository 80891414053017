.news-item-wrapper{
    min-height: 400px;
    padding: 30px;
    background-color: #d9d8d857;
    border: 1px solid #d9d8d8f2;
}

.news-item-wrapper .news-title{
    margin-bottom: 14px;
    font-weight: bold;
}

.news-item-wrapper .location{
    font-weight: bold;
}

.news-item-wrapper .publish-date{
    font-size: medium;
    font-style: italic;
    margin-bottom: 10px !important;
}