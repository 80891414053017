.admissionPage-heading {
  color: rgb(85, 85, 85);
}

.admissionPage-list {
  list-style-type: none;
}

.admissionPage-list-item {
  background-color: #f7f7f7;
  margin-top: 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admissionPage-download-btn {
  border: none;
  font-size: 0.8rem;
  padding: 0.7rem 1rem;
  color: rgb(107, 107, 107);
}

.admissionPage-download-btn:hover {
  background-color: #0033a0;
  color: white;
}

.admissionPage-date {
  color: red;
}

.admissionPage-link-item {
  text-align: justify;
  padding-right: 0.5rem;
}

.admissionPage-link-item:hover {
  text-decoration: underline;
}

.admissionPage-pagination {
  list-style-type: none;
  display: flex;
}

.admissionPage-pageNumber:hover {
  background-color: #267264 !important;
  color: white !important;
}

.admissionPage-pageNumber:focus {
  outline: none !important;
  box-shadow: none !important;
}

.admissionPage-admissionIcon {
  border: none;
  font-size: 0.8rem;
  padding: 0.7rem 1rem;
  color: rgb(107, 107, 107);
}

.admissionPage-link {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.admissionPage-downloadtext {
  margin-right: 0.3rem;
}

@media only screen and (max-width: 700px) {
  .admissionPage-pageNumber {
    font-size: 0.7rem !important;
  }

  .admissionPage-pagination {
    padding-left: 0;
  }

  .admissionPage-downloadtext {
    display: none;
  }

  .admissionPage-link-item {
    font-size: 0.7rem;
  }

  .admissionPage-heading {
    font-size: 1rem;
    margin-left: 0 !important;
  }
}

.admissionPage-tabs {
  background-color: #ececec;
}

.admissionPage-tabs .nav-link {
  border-radius: 0 !important;
}

.admissionPage-tabs .active {
  color: #f7f7f7 !important;
}
