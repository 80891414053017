/* Gallery Page */
.error-box {
  margin: 0 !important;
}

.galleryPage-galleryItem {
  background-color: rgb(245, 245, 245);
  display: inline-block;
  padding: .5rem;
  border-radius: 2px;
  box-shadow: 0 0 5px rgb(219, 219, 219);
  overflow: hidden;
}

.galleryPage-galleryItem>img {
  transition: all 300ms;
}

.galleryPage-galleryItem:hover>img {
  transform: scale(1.05);
}

/* Gallery Content Page */
.galleryContent-gallery {
  column-count: 5;
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-width: 25%;
  -webkit-column-width: 25%;
  -moz-column-width: 25%;
}

.galleryContent-imageWrapper {
  margin-bottom: 1rem;
  border: 1px solid rgb(236, 236, 236);
  cursor: pointer;
  position: relative;
  transition: all .2s ease-in;
  -moz-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
}

.galleryContent-imageWrapper:hover>.galleryContent-image-overlay {
  filter: opacity(1);
}

.galleryContent-image-overlay {
  transition: all .3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.603);
  filter: opacity(0);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoom-icon {
  font-size: 2rem;
  color: rgb(235, 235, 235);
  padding: .4rem .7rem .3rem .7rem;
  border-radius: 100%;
  transition: all .2s ease-in;
  -moz-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(21, 21, 21, .75);
}

.modal-body {
  position: relative;
  z-index: 11;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 3px;
}

.modal-close {
  top: 20px;
  right: 20px;
  color: black;
  background-color: rgba(255, 255, 255, 0.352);
  border-radius: 3px;
  font-size: 1.6rem;
  position: absolute;
  transition: all .2s ease-in;
  padding: 0 .6rem;


}

.active-image {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.image-caption {
  background-color: rgb(90, 90, 90);
  padding: .7rem 1.5rem;
  color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}


/* responsive Images content view */
@media (max-width: 1400px) {
  .galleryContent-gallery {
    column-count: 4;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-width: 33%;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
  }
}

@media (max-width: 900px) {
  .galleryContent-gallery {
    column-count: 3;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-width: 50%;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
  }
}

@media (max-width: 590px) {
  .galleryContent-gallery {
    column-count: 1;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-width: 100%;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
  }
}